<template>
  <div class="settingsPage">
    <div v-if="user && isMounted" class="row">
      <div class="col-12">
        <div class="tabs">
          <b-nav tabs>
            <b-nav-item to="#" :active="$route.hash === '#' || $route.hash === ''">
              Settings
            </b-nav-item>
            <b-nav-item to="#emails" :active="$route.hash === '#emails'">
              Emails
            </b-nav-item>
            <b-nav-item to="#privacy" :active="$route.hash === '#privacy'">
              Privacy
            </b-nav-item>
            <b-nav-item to="#billing" :active="$route.hash === '#billing'">
              Billing
            </b-nav-item>
          </b-nav>
          <div class="tab-content">
            <div :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="updateForm"
                  >
                    <form
                        class="settings-form"
                        @submit.prevent="handleSubmit(updateProfile)"
                    >
                      <div class="row">
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.first_name"
                              name="first_name"
                              rules="required"
                              label="First Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.last_name"
                              name="last_name"
                              rules="required"
                              label="Last Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <label>Phone Number</label>
                          <VuePhoneNumberInput v-model="user.phone_number" :error="phoneError" valid-color="#e2e7f1" @update="setPhone" />

                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.email"
                              name="email"
                              label="Email Address"
                              rules="required|email"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-lg-3">
                          <TextInput
                              v-model="user.agency.name"
                              name="company_name"
                              rules="required"
                              label="Company name"
                          />
                        </div>
                        <div class="col-md-5 col-lg-6">
                          <div class="row">
                            <div class="col">
                              <label class="d-block m-0">Notifications</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="d-inline-block mr-3 mb-3">
                                <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                                  <span v-if="user.agency.settings.notify_initial_searches">Enabled</span>
                                  <span v-else>Disabled</span>
                                </small>
                                <div class="d-inline-flex">
                                  <span class="mr-2 d-flex">Initial Searches</span>
                                  <b-form-checkbox
                                      v-model="user.agency.settings.notify_initial_searches"
                                      size="sm"
                                      name="initial-button"
                                      switch
                                      inline
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                              <div class="d-inline-block" style="min-width: 171px; text-align: right">
                                <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                                  <span v-if="user.agency.settings.notify_new_leads">Enabled</span>
                                  <span v-else>Disabled</span>
                                </small>
                                <div class="d-inline-flex">
                                  <span class="mr-2 d-flex">New Leads</span>
                                  <b-form-checkbox
                                      v-model="user.agency.settings.notify_new_leads"
                                      size="sm"
                                      name="newl-button"
                                      switch
                                      inline
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 align-self-end text-right mt-md-0 mt-3">
                          <b-form-group>
                            <b-button
                                variant="primary"
                                class="btn-dark-blue"
                                :disabled="loadingUpdate || (invalid && touched)"
                                type="submit"
                            >
                              <b-spinner v-if="loadingUpdate" small />
                              <span v-else>Update</span>
                            </b-button>
                          </b-form-group>
                        </div>
                      </div>

                    </form>
                  </ValidationObserver>
                  <WebHook />
                </div>
              </div>
              <PasswordReset />
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#emails' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="emailsForm"
                  >
                    <form
                        class="emails-form"
                        @submit.prevent="handleSubmit(updateEmails)"
                    >
                      <div class="row">
                        <div class="col mb-3">
                          <label>Email when a lead opts-in</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="user.agency.settings.email_name"
                              name="email_name"
                              rules="required"
                              margin-bottom="0"
                              placeholder="From Name"
                              label="From Name"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="user.agency.settings.email_address"
                              name="email_address"
                              rules="required|email"
                              margin-bottom="0"
                              placeholder="From Name"
                              label="From Email"
                          />
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <vue2-tinymce-editor :id="'email_message' + user.agency.id" v-model="user.agency.settings.email_message"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <b-form-group class="text-right mt-3">
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#privacy' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="privacyForm"
                  >
                    <form
                        class="privacy-form"
                        @submit.prevent="handleSubmit(updatePrivacy)"
                    >
                      <div class="row">
                        <div class="col">
                          <label>
                            Privacy Policy
                          </label>
                          <vue2-tinymce-editor id="privacy-policy" v-model="user.agency.settings.privacy_policy"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <label>
                            Terms Of Service
                          </label>
                          <vue2-tinymce-editor v-if="privacyReady" :id="'terms-of-service' + user.agency.id" v-model="user.agency.settings.terms_of_service"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <b-form-group class="text-right mt-3">
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#billing' }]">
              <div class="card">
                <div class="card-body">
                  <SettingsPaymentMethods />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SettingsPaymentMethods from "@components/Agency/PaymentMethods/SettingsPaymentMethods";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import PasswordReset from "@components/Common/PasswordReset";
import WebHook from "@components/Common/WebHook";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components:{
    SettingsPaymentMethods,
    Vue2TinymceEditor,
    PasswordReset,
    WebHook,
    VuePhoneNumberInput
  },
  data() {
    return {
      showVideoModal: false,
      loadingUpdate: false,
      loadingRemove: null,
      showDomainModal: false,
      privacyReady:false,
      phoneError:false,
      isMounted:false,
      user: {
        phone_number:'',
        agency: {
          whitelabel: {},
          settings:{}
        },
      },
    }
  },
  computed:{

  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.setupPrivacy();
    this.isMounted = true;
  },

  methods: {
    setPhone(phone){
      // this.userPhone = phone.formatInternational;
    },
    async setupPrivacy() {
      // this is stupid but tinymce is creating muliple editors with the same id because they are init at the same time
      await new Promise(resolve => setTimeout(resolve, 500));
      this.privacyReady = true;
    },
    openVideoModal() {
      this.showVideoModal = true
    },

    updateProfile() {
      this.loadingUpdate = true
      this.phoneError = false;
      let params = {
        company_name: this.user.agency.name,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone_number,
        email: this.user.email,
        notify_initial_searches:this.user.agency.settings.notify_initial_searches,
        notify_new_leads:this.user.agency.settings.notify_new_leads,
        setting_form:'settings'
      }

      this.$store
        .dispatch('agency/editSettings', params)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors);
            if(Object.keys(err.response.data.errors).includes('phone')){
              this.phoneError = true;
            }
          }
          this.loadingUpdate = false
        })
    },
    updatePrivacy(){
      this.loadingUpdate = true

      let params = {
        privacy_policy: this.user.agency.settings.privacy_policy,
        terms_of_service: this.user.agency.settings.terms_of_service,
        setting_form:'privacy'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    updateEmails(){
      this.loadingUpdate = true

      let params = {
        email_name: this.user.agency.settings.email_name,
        email_address: this.user.agency.settings.email_address,
        email_message: this.user.agency.settings.email_message,
        setting_form:'emails'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    handleExplain() {
      this.showDomainModal = true
    },
  },
}
</script>

<style lang="scss">
.settingsPage {
  .boxFile {
    .input-group {
      background-color: #4284f4;
      border-color: 1px solid #4284f4;
      border-radius: 0.3rem;
      color: #fff;
      input[type='file'] {
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 9px;
        left: calc(50% - 45px);
        font-weight: 600;
        color: #fff;
        pointer-events: none;
        content: 'Upload File';
      }
      &:hover {
        background-color: #1e6cf2;
        border-color: #1265f1;
        &::before {
          color: #fff;
        }
      }
    }
    .form-control {
      opacity: 0;
    }
  }
}
#MazPhoneNumberInput {
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
</style>
